<template>
  <v-container fluid>
    <v-card outlined class="px-4 pb-0 mx-5 mb-5">
      <v-card-title>Ativar reaplicação automática de assinaturas</v-card-title>

      <v-card-text>
        Com a reativação automática de assinaturas cada usuário terá sua
        assinatura de e-mail reaplicada diariamente automaticamente.
      </v-card-text>

      <Alert
        v-if="!hasConectaControl"
        dense
        color="warning"
        class="mb-0 ml-4"
        :actionText="'Entrar em contato'"
        :actionIcon="''"
        @action="scheduleMeeting"
      >
        Para ativar a reaplicação automática de assinaturas é preciso contratar
        o Plano Security ou superior.
      </Alert>
      <Alert
        dense
        v-if="!company.is_sign_automation_active && hasConectaControl"
        color="warning"
        class="mb-0 ml-4"
      >
        Ao ativar o Sign Automation, a reaplicação automática de assinaturas
        será implementada para todos os usuários da empresa que tiverem um
        modelo de assinatura aplicado.
      </Alert>
      <Alert
        dense
        v-if="company.is_sign_automation_active"
        color="success"
        class="mb-0 ml-4"
      >
        <b>O Sign Automation está ativo.</b> Ao desativar, as assinaturas não
        serão mais reaplicadas automaticamente.
      </Alert>
      <v-card-actions class="px-4 mt-2">
        <v-btn
          x-large
          @click="changeSignAutomation"
          class="px-5 text-none text-body-1 font-weight-medium"
          color="accent"
          :loading="loading"
        >
          <v-icon
            v-text="
              company.is_sign_automation_active
                ? 'mdi-autorenew-off'
                : 'mdi-autorenew'
            "
            left
            size="28"
            class="mr-3"
          />
          {{ btnName }}
          automação
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card outlined class="px-4 pb-6 mx-5">
      <v-card-title> Aplicações de assinaturas agendadas </v-card-title>
      <v-card-text class="mx-0 px-0">
        <v-data-table
          :items="signatureSchedules"
          :headers="headers"
          :items-per-page="50"
          :loading="loading"
          single-expand
          :height="tableHeight"
          fixed-header
          show-expand
          item-key="key"
          item
        >
          <!-- AUTHOR HEADER-->
          <template v-slot:[`header.author`]="{ header }">
            {{ header.text }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  v-text="'mdi-help-circle'"
                  small
                />
              </template>
              <span class="font-weight-medium">
                O usuário que executou a ação
              </span>
            </v-tooltip>
          </template>

          <!-- CREATED AT -->
          <template v-slot:[`item.created_at`]="{ item }">
            {{ formatDateAndHour(item.created_at, true) }}
          </template>

          <template v-slot:[`item.schedule_date`]="{ item }">
            {{ formatDateAndHour(item.schedule_date, false) }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-icon
              left
              dense
              v-text="getStatus(item.status, 'icon')"
              :color="getStatus(item.status, 'color')"
            />
            {{ getStatus(item.status, "text") }}
            <v-tooltip bottom v-if="item.status === 'CREATED'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  v-text="'mdi-help-circle'"
                  small
                />
              </template>
              <span class="font-weight-medium">
                Comunique nosso suporte para identificar uma possível falha.
              </span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.author`]="{ item }">
            <Author :author="item.author || {}" />
          </template>

          <template v-slot:[`item.template`]="{ item }">
            <span>
              {{ getTemplate(item.template_key)?.name }}
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  data-testid="options"
                  icon
                  class="ml-auto"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon v-text="'mdi-dots-vertical'" />
                </v-btn>
              </template>
              <v-list dense class="py-0">
                <v-list-item
                  class="text-body-2 font-weight-medium"
                  @click="setTemplatePreview(item.template_key)"
                >
                  Visualizar assinatura aplicada
                </v-list-item>
                <v-divider />
                <v-list-item
                  :disabled="item.status !== 'SCHEDULED'"
                  @click="deleteSchedule(item.key)"
                  class="text-body-2 font-weight-medium"
                >
                  Cancelar agendamento
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pl-4 pt-4 pb-4 grey lighten-4">
              <div>
                <li class="text-bold" v-if="!item.to_users.length">
                  {{ "Dados não encontrados" }}
                </li>
                <li v-else>
                  Aplicado para:
                  {{ item.to_users.join(", ") }}
                </li>
              </div>
            </td>
          </template>

          <template
            v-slot:item.data-table-expand="{ item, isExpanded, expand }"
          >
            <v-icon
              :class="[
                'v-data-table__expand-icon',
                { 'v-data-table__expand-icon--active': isExpanded },
              ]"
              @click.stop="expand(!isExpanded)"
              >$expand</v-icon
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <SimpleDialog
      :title="`Visualizando assinatura ${templateName}`"
      :show="showPreview"
      @close="showPreview = false"
      actionText="Fechar"
      @action="showPreview = false"
      width="800"
    >
      <template v-slot:body>
        <TemplatePreview
          :html="htmlTemplate"
          :infoToRender="currentUser"
          signature-only
        />
      </template>
    </SimpleDialog>
  </v-container>
</template>
<script>
import { tableHeight } from "@/helpers/variables/tablesDefault";
import { formatDateAndHour } from "@/helpers/services/utils";
import Author from "@/components/logs/cells/Author.vue";
import { getAnalytics, logEvent } from "firebase/analytics";
import moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";
import TemplatePreview from "@/components/sign/general/TemplatePreview";

export default {
  name: "SignAutomation",
  components: {
    Author,
    TemplatePreview,
  },
  data() {
    return {
      loading: false,
      tableHeight,
      template_key: "",
      showPreview: false,
      htmlTemplate: "",
      templateName: "",
      status: {
        CREATED: {
          text: "Falha ao agendar",
          icon: "mdi-shield-alert",
          color: "warning",
        },
        SCHEDULED: {
          text: "Agendado",
          icon: "mdi-clock",
          color: "accent",
        },
        EXECUTED: {
          text: "Executado",
          icon: "mdi-shield-check",
          color: "success",
        },
        DELETED: {
          text: "Cancelado",
          icon: "mdi-shield-off",
          color: "error",
        },
      },
    };
  },
  methods: {
    ...mapMutations(["setSnackBar", "setCompany"]),
    ...mapActions([
      "getSignatureSchedules",
      "getDefaultTemplates",
      "getCustomTemplates",
      "deleteSignatureSchedule",
      "verifyHubspotToken",
    ]),

    formatDateAndHour,

    scheduleMeeting() {
      const analytics = getAnalytics();
      const { main_domain } = this.company;
      const clicked_where = "opened from sign automation";

      logEvent(analytics, "schedule_meeting", {
        main_domain,
        clicked_where,
        date: moment().format("DD/MM/YYYY"),
      });

      this.verifyHubspotToken();
    },

    async changeSignAutomation() {
      let payload = {
        is_sign_automation_active: !this.company.is_sign_automation_active,
      };
      const url = `${process.env.VUE_APP_API_BASE_URL}/company`;
      this.loading = true;
      this.$axios
        .put(url, payload, {
          headers: { Authorization: this.token },
        })
        .then(({ data }) => {
          this.setCompany(data);
          this.setSnackBar({
            message: this.company.is_sign_automation_active
              ? "Reaplicação automática DESATIVADA com sucesso"
              : "Reaplicação automática ATIVADA com sucesso",
            show: true,
            color: "success",
          });
        })
        .catch((e) => {
          console.error("changeSignAutomation(): ", e);
          this.setSnackBar({
            message: "Não foi possível alterar a reaplicação automática.",
            show: true,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getStatus(status, type) {
      return this.status[status][type];
    },
    setTemplatePreview(key) {
      const template = this.getTemplate(key);
      this.htmlTemplate = template.html;
      this.templateName = template.name;
      this.showPreview = true;
    },
    getTemplate(key) {
      return this.allTemplates.find((item) => item.id === key);
    },
    deleteSchedule(key) {
      this.deleteSignatureSchedule(key)
        .then(() => {
          this.setSnackBar({
            message: "Sucesso no cancelamento do agendamento",
            show: true,
            color: "success",
          });
        })
        .catch((e) => {
          console.error("deleteSignatureSchedule(): ", e);
          this.setSnackBar({
            message:
              "Não foi possível remover o agendamento. Contate o suporte da Conecta Suite.",
            show: true,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters([
      "hasConectaControl",
      "company",
      "token",
      "currentUser",
      "signatureSchedules",
      "currentUser",
      "allTemplates",
    ]),
    btnName() {
      return this.company.is_sign_automation_active ? "Desativar" : "Ativar";
    },
    headers() {
      return [
        {
          value: "created_at",
          text: "Data do registro",
          width: 180,
          sortable: false,
        },
        {
          value: "schedule_date",
          text: "Data agendada",
          width: 180,
          sortable: false,
        },
        {
          value: "status",
          text: "Situação",
          width: 180,
          sortable: false,
        },
        {
          value: "author",
          text: "Ator",
          sortable: false,
          width: 150,
        },
        {
          value: "template",
          text: "Assinatura",
          sortable: false,
          width: 200,
        },
        {
          value: "actions",
          text: "",
          sortable: false,
          width: 20,
        },
      ];
    },
  },
  async beforeMount() {
    await this.getSignatureSchedules();
    if (!this.allTemplates.length) {
      await this.getDefaultTemplates();
      await this.getCustomTemplates();
    }
  },
};
</script>
